<template>
  <div class="result-table">
      <Table :tableData="tableData" >
        <el-table-column
        prop="No"
        label="序号"
        width="50"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="type"
        label="类型"
        width="150"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column
        prop="title"
        label="标题"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column
        prop="intro"
        label="简介"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column
        label="操作"
        width="100"
        show-overflow-tooltip
      >
        <template slot-scope="scope">
          <a :href="scope.row.url" class="control-measures-download">附件下载</a>
        </template>
      </el-table-column>
      </Table>  
  </div>
</template>

<script>

import Table from '@/components/Table.vue';

export default {
  name: "TableList",
  props: ['tableData'],
  components: { Table },
};
</script>

<style lang="less">
.result-table{
  flex: 1 1 auto;
  overflow: auto;
}

.control-measures-download{
  color: #7DEBFF;
  &:hover{
    color: #44dffa;
  }
}
</style>