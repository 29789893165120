<template>
    <div class="laws-regulations-view">
        <div class="ledger-shopsearch">
            <LedgerList title="功能目录" :list="ledgerList" @onClick="onLedgerClick" :active="ledgerActive"/>
        </div>
        <div :class="{'table-charts-view': true, charts: !ledgerActive}">
            <div class="table-modal" v-if="ledgerActive">
                <TableList :title="ledgerActive.name" @onClick="onTableClose" />
            </div>
        </div>
    </div>
</template>

<script>

import LedgerList from '@/components/LedgerList.vue';
import TableList from './table-list/Index.vue'

export default {
    name: 'LawsRegulationsView',
    components: { LedgerList, TableList },
    data(){
        return {
            ledgerActive: null,
            ledgerList: [
               { key: '1', name: '法律法规' },
               { key: '2', name: '政策文件' }
            ]
        }
    },
    mounted(){
        //默认打开法律法规
       this.ledgerActive =  this.ledgerList[0];
    },
    methods:{
        //点击隐患台账清单
        onLedgerClick(item){
            const isActive = this.ledgerActive && item.key === this.ledgerActive.key;
            this.ledgerActive =  isActive ? null : item;
        },
        
        //点击表格关闭按钮
        onTableClose(flag){
            this.ledgerActive = flag;
        },
    } 
}
</script>

<style lang="less">
.laws-regulations-view{
    height: 100%;
    display: flex;
    padding: 20px;
    box-sizing: border-box;
    position: relative;
    .ledger-shopsearch{
        display: flex;
        flex-direction: column;
        padding: 60px 0 0 0px;
    }
    .table-charts-view{
        flex: 1 1 auto;
        padding-left: 15px;
        &.charts{
            display: flex;
            justify-content: flex-end;
        }
        .table-modal{
            width: 100%;
            height: 100%;
            border-radius: 5px;
            pointer-events: all;
        }
        .charts-modal{
            width: 325px;
            height: 100%;
            border-radius: 5px;
            pointer-events: all;
        }
    }
    .footer-cricle-view{
        // border: 1px solid #aaa;
        position: absolute;
        bottom: 20px;
        left: 20px;
        pointer-events: all;
        display: flex;
    }
}
</style>