<template>
    <BorderBox :bg="true">
        <div class="table-list">
            <Title :text="title" class="title-close">
                <div class="close" @click="closeTable"> <i class="el-icon-close"></i> </div> 
            </Title>    
            <TableList :tableData="tableData" />
            <Pagination :data="pagination" @onSizeChange="onSizeChange" @onCurrentChange="onCurrentChange" />
        </div>
    </BorderBox>
</template>

<script>

import Title from '@/components/Title.vue';
import TableList from './TableList.vue';
import Pagination from '@/components/PaginationVew.vue';

import BorderBox from '@/components/BorderBox.vue';

export default {
    name: 'table-list',
    components: { Title, TableList, Pagination, BorderBox },
    props: ['title', 'onClick'],
    data(){
        return {
            lawsRegulationsList: [],
            policyList: [],
            tableData:[
                // {
                //     No, //序号
                //     type, //类型
                //     title, //标题
                //     intro, //简介
                //     url, //下载地址
                // }
            ],
            pagination: {  //分页信息
                page: 1,  // 页数(0-全部)
                count: 10, // 每页数量
                total: 0
            }

        }
    },
    watch:{
        title(cur){
            
            this.pagination ={
                page: 1,  // 页数(0-全部)
                count: 10, // 每页数量
                total: 0
            }
            this.getList();
        }
    },
    mounted() {
        this.getList();
    },
    methods: {
        //关闭表格事件
        closeTable(){
            this.$emit('onClick', null);
        },
       
        // 分页组件页面size变化
        onSizeChange(count){
            this.pagination = {...this.pagination, count};  
            this.getList();
        },

        // 分页组件页面当前页变化
        onCurrentChange(page){
            this.pagination = {...this.pagination, page};
            this.getList();
        },

        getList(){
            if(this.title === '法律法规') {
                this.getLawsRegulationsList();
            }else{
                this.getPolicyList();
            }
        },

        //法律法规文件列表
        async getLawsRegulationsList(){
            //16-获得法律法规文件列表
            const params = { pagination: this.pagination };

            let [res, err] = await this.$Fx('/Api_Area/GovernmentFile/LawsRegulations/getLawsRegulationsList', params);
            
            if(!err && res){
                this.tableData = res.data.laws_regulations_list.map(item=>{
                    const {
                        No, 
                        laws_regulations_type_name: type, 
                        laws_regulations_title: title, 
                        laws_regulations_intro: intro, 
                        laws_regulations_file_info: {url}
                    } = item;

                    return { No,type, title, intro, url }
                })

                //更新分页信息
                const { count, total} = res.data.paginated;
                this.pagination = { ...this.pagination, count: Number(count), total: Number(total) };
            }
        },
        //政策文件列表
        async getPolicyList(){

            //17-政策文件-获得政策文件列表
            const params = { pagination: this.pagination };

            let [res, err] = await this.$Fx('/Api_Area/GovernmentFile/Policy/getPolicyList', params);

            if(!err && res){
                this.tableData = res.data.policy_list.map(item=>{
                    const {
                        No, 
                        policy_type_name: type, 
                        policy_title: title, 
                        policy_intro: intro, 
                        policy_file_info: {url}
                    } = item;

                    return { No,type, title, intro, url }
                })

                //更新分页信息
                const { count, total} = res.data.paginated;
                this.pagination = { ...this.pagination, count: Number(count), total: Number(total) };
            }
        }
    },
}
</script>

<style lang="less">
.laws-regulations-view{
    .table-list{
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        padding: 10px;
        box-sizing: border-box;
    }
}

.title-close{
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
    height: 40px;
    padding-bottom: 15px;
    padding-left: 10px;
    .close{
        width: 20px;
        height: 20px;
        color: #fff;
        font-size: 18px;
        font-weight: bold;
        cursor: pointer;
        &:hover{
            transform: scale(1.2);
            color: #7DEBFF;
        }
    }
}

</style>